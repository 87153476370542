
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar.vue'
// import { useQuery } from '@urql/vue';
import { Manga } from '@/types';

export default defineComponent({
  name: 'Archive',
  components: {
    NavBar
  },
  data() {
    return {
      data: {} as Manga[],
      loading: true,
      error: false as string | false
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    /* Fetch data from the api */
    async getdata(callback?: () => void) {
      this.loading = true
      const data = {
        mangas: (await this.axios.get('/api/mangas.json')).data
      }

      this.error = data.mangas.length==0 ? 'Nessun risultato' : false
      this.data = this.error ? this.data : data.mangas

      /** onyl call if defined */
      callback && callback()
      this.loading =  false
    }
  }
})
